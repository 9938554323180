showTrips = function(ds_id) {
  document.querySelector("#trips_" + ds_id).classList.remove("d-none");
  document.querySelector("#show_" + ds_id).classList.add("d-none");
  document.querySelector("#hide_" + ds_id).classList.remove("d-none");
  document.querySelector("#ds_row_" + ds_id).classList.add("highlight");
};

hideTrips = function(ds_id) {
  document.querySelector("#trips_" + ds_id).classList.add("d-none");
  document.querySelector("#show_" + ds_id).classList.remove("d-none");
  document.querySelector("#hide_" + ds_id).classList.add("d-none"); 
  document.querySelector("#ds_row_" + ds_id).classList.remove("highlight"); 
};

toggleUnlocks = function(trip_id) {
  document.querySelector("#unlocks_" + trip_id).classList.toggle("d-none");
}

showThisLog = function(id) {
  document.querySelector("#log_" + id).classList.toggle("d-none");
  console.log(id);
}
